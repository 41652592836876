export default {
    name: "LandingPage",
    created () {
        this.$nextTick(function () {
            this.getSeminardata();
            this.fullText()
            window.Seminarid = this.Seminarid;
            window.fullText = this.fullText
            // this.EmaliV()
        });
    },
    mounted () {
        this.$nextTick(function () { });
    },
    data () {
        return {
            seminaridNum: null,
            email: null,
            disclaimer: 0
        };
    },
    computed: {
        //写成计算属性
        address () {
            const { disclaimer, email } = this;
            return {
                disclaimer,
                email
            }
        }
    },
    watch: {
        address (val, newval) {
            if (val.email && val.disclaimer === 1) {
                $('#submit').removeAttr('disabled', false)
            } else {
                $('#submit').attr('disabled', true)
            }
        }
    },
    methods: {
        // 获取Seminar数据
        getSeminardata: function () {
            let _this = this;
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedSeminar",
                data: { token: "webkey", lang: window.sessionStorage.getItem('lang') },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        _this.matrixSeminar(data.text);
                        console.log(data);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 赋值
        matrixSeminar: function (data) {
            console.log(data);
            let _this = this
            var $timing = $(".seminar_timing");
            var $particular = $(".seminar_particular");
            var timingHtml = "";
            var particularHtml = "";
            if (data.length === 0) {
                $(".seminar_meeting")
                    .text("There are no seminars available at the moment.")
                    .css({
                        "justify-content": "center"
                    });
            }
            for (var i = 0, max = data.length; i < max; i++) {
                var sDate = data[i].seminar_date.split(" ")[0];
                if (i === 0) {
                    timingHtml +=
                        '<li class="row seminar_timing_li seminar_choice_li cur-pointer s_li">';
                } else {
                    timingHtml += '<li class="row seminar_timing_li cur-pointer s_li">';
                }
                timingHtml +=
                    '<span class="col-3 row"><span class="col col-lg-4">' +
                    this.SeminarDate(sDate)[0] +
                    '</span><span class="col col-lg-4">' +
                    this.SeminarDate(sDate)[1] +
                    '</span><span class="col col-lg-4">' +
                    this.SeminarDate(sDate)[2].substring(4 - 2, 4) +
                    "</span></span>";
                timingHtml += '<p class="col pl-3">' + data[i].title + "</p>";
                timingHtml += "</li>";

                particularHtml += '<li class="seminar_particular_li">';
                particularHtml += '<p class="title">' + data[i].title + "</p>";
                particularHtml +=
                    '<p class="time">' +
                    this.SeminarDate(sDate)[0] +
                    "&nbsp;" +
                    this.SeminarDate(sDate)[1] +
                    "&nbsp;" +
                    this.SeminarDate(sDate)[2] +
                    "&nbsp;&nbsp;&nbsp;&nbsp;" +
                    this.SeminarTime(data[i].start_time, data[i].end_time)[1] +
                    "</p>";
                particularHtml += '<p class="site">' + data[i].location + "</p>";
                particularHtml += '<div class="content">' + data[i].content + "</div>";
                if (this.$js.spaceData(data[i].content) !== "N/A" && data[i].capacity === 'JOIN') {
                    particularHtml +=
                        '<div class="button"><button type="button" class="btn btn-primary seminar-but" data-toggle="modal" data-target="#RegisterEmail"><a class="seminar-a" href="javascript:Seminarid(\'' + data[i].seminarid + '\')">' + this.$t("m.ClickHeretoRegister") + '</a></button></div>';
                } else if (this.$js.spaceData(data[i].content) !== "N/A" && data[i].capacity === 'FULL') {
                    particularHtml +=
                        '<div class="button"><button type="button" id="full" class="btn btn-primary" data-toggle="modal" data-target="#Reminder2" onclick="fullText()">' + this.$t("m.ClickHeretoRegister") + '</button></div>'
                } else if (this.$js.spaceData(data[i].content) !== "N/A" && data[i].capacity === 'WEB') {
                    particularHtml += '<div class="button"><button type="button" class="btn btn-primary"><a href="' + data[i].url + '" target="_blank">' + this.$t("m.ClickHeretoRegister") + '</a></button></div>'
                }

                particularHtml += "</li>";
            }
            $timing.append(timingHtml);
            $particular.append(particularHtml);

            this.SeminarIncident($timing, $particular);
        },
        SeminarIncident: function (timing, particular) {
            let _this = this
            timing.find(".s_li").click(function () {
                var index = timing.find(".s_li").index($(this));
                _this.$js.SetAnchor($('.seminar_particular').offset().top)
                $(this).addClass("seminar_choice_li");
                $(this)
                    .siblings(".s_li")
                    .removeClass("seminar_choice_li");
                particular
                    .find(".seminar_particular_li")
                    .eq(index)
                    .show();
                particular
                    .find(".seminar_particular_li")
                    .eq(index)
                    .siblings(".seminar_particular_li")
                    .hide();
            });
        },
        SeminarDate: function (date) {
            var nds = [];
            var dds = date.split("-");
            var MMM = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            if (dds.length === 3) {
                var dd = Number(dds[2]);
                var mm = MMM[Number(dds[1]) - 1];
                var yyyy = dds[0];
                nds[0] = dd;
                nds[1] = mm;
                nds[2] = yyyy;
            }
            return nds;
        },
        SeminarTime: function (stime, etiem) {
            var tarr = [];
            var ssd = stime.split(" ")[0].split("-");
            var sst = stime.split(" ")[1];
            var esd = etiem.split(" ")[0].split("-");
            var est = etiem.split(" ")[1];
            var M = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            if (ssd.length === 3) {
                var sd = Number(ssd[2]);
                var sm = M[Number(ssd[1]) - 1];
                var sy = parseInt(ssd[0]);

                var ed = Number(esd[2]);
                var em = M[Number(esd[1]) - 1];
                var ey = parseInt(esd[0]);

                if (sy === ey || sm === em || sd === ed) {
                    tarr[0] = sd + " " + sm + " " + sy;
                } else {
                    tarr[0] = sd + " " + sm + " " + sy + "-" + ed + " " + em + " " + ey;
                }
            }

            var sh = Number(sst.split(":")[0]);
            var shh = sst.substring(0, 5);
            var sph;
            if (sh < 12) {
                sph = shh + " am";
            } else {
                if (sh > 12) {
                    if (sh < 10) {
                        sh = "0" + (sh - 12)
                    } else {
                        sh = (sh - 12)
                    }
                }
                sph = sh + ":" + sst.split(":")[1] + " pm";
            }

            var eh = Number(est.split(":")[0]);
            var ehh = est.substring(0, 5);
            var eph;
            if (eh < 12) {
                eph = ehh + " am";
            } else {
                if (eh > 12) {
                    if (eh < 10) {
                        eh = "0" + (eh - 12)
                    } else {
                        eh = (eh - 12)
                    }

                }
                eph = eh + ":" + est.split(":")[1] + " pm";
            }
            tarr[1] = sph + " " + "-" + " " + eph;
            return tarr;
        },
        Seminarid: function (s) {

            this.seminaridNum = s
        },
        checkboxOnclick () {
            if ($('#signCheck').prop('checked') == true) {
                this.disclaimer = 1
            } else {
                this.disclaimer = 0
            }
        },
        Register: function () {
            let timer1
            let _this = this
            if (!/\w+@.+\..+$/.test(this.email)) {
                alert("Please confirm the correct information.")
                $('#email').val('')
            } else {
                $.ajax({
                    type: "post",
                    url: this.$js.webservice + "RegisterEvent",
                    data: { action: "add", seminarid: this.seminaridNum, email: this.email, disclaimer: this.disclaimer },
                    dataType: "json",
                    success: function (data) {
                        if (data.status === "SUCCESS") {
                            $('#Reminder').find('.modal-body').text('Thank you for the registration. Details will be sent to your email, keep a look out!')
                            $('#Reminder').modal('show')
                            $('#email').val('')

                        } else if (data.status === "ERROR") {
                            $('#Reminder').find('.modal-body').text(data.replymsg)
                            $('#Reminder').modal('show')
                            $('#email').val('')
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
            }
        },
        Close () {
            this.$router.go(0)
        },
        upPersonalActPDPA () {
            var routes = this.$router.resolve({
                path: "/id/PersonalActPDPA",
            });
            // location.href = routes.href;
            window.open(routes.href)
        },
        fullText: function () {
            let Ftext = 'Thank You for your interest in our seminar! This seminar has reached its maximum capacity. However, you may drop us an email at rhbwarrant@rhbgroup.com and our warrant specialists will be happy to assist you.'
            $('#Reminder2').find('.modal-body').text(Ftext)
        }
    }
};